
  <!-- Toolbar -->
  <div class="toolbar" role="banner">
    <img
    width="40"
    alt="HLS Logo"
    src="assets/images/icon_sabah.png"
  />
    <span><h2>HASIL LAUT SABAH</h2></span>
      <div class="spacer"></div>
  </div>

    <div class="coverWrapper">
      <div class="hero-text">
        <h1 class="hero-text-header">
          MENJUAL HASIL LAUT SEGAR
        </h1>
        <h3>Tiada masa ke pasar ?? Kami menyediakan perkhidmatan penghantaran (COD) terus ke pintu rumah.</h3>
        <div class="card-container">
          <a class="card" href="#contact-us">
            <span><b>ORDER NOW!</b></span>  
          </a>
        </div>
        
      </div>
    </div>


  <div class="content" role="main">

  <!-- About Us -->
  <h2>Tentang Kami</h2>
  <p>
    Kami menjual hasil laut basah & kering yang beroperasi 
    di Putatan Sabah. Hasil laut yang kami jual adalah dijamin
    <b>SEGAR/FRESH</b>. 
    Kami menyediakan perkhidmatan COD (kawasan berdekatan) & POS (Semenanjung/Sarawak).
  </p>
  <br />
  
  <h2>Kenapa harus membeli dengan kami?</h2>
  <div class="card-container">
    <a class="card">
      <span>Jujur & Amanah</span>  
    </a>

    <a class="card">
      <span>Kualiti & Kebersihan  Terjamin</span>
    </a>

    <a class="card">
      <span>Harga berpatutan</span>
    </a>

    <a class="card">
      <span>Hasil laut segar</span>
    </a>

    <a class="card">
      <span>Penjual yang peramah</span>
    </a>
  </div>

  <br />
  <!-- COD -->
  <h2>Penghantaran / Delivery</h2>
  <p>COD : RM2.00 ~ RM5.00</p>
  <p>Masa : 10.30 a.m ~ 12.00 p.m</p>
  <p>SETIAP HARI !</p>

  <div class="card-container">
    <div class="card card-small" tabindex="0">
        <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>

      <span>PUTATAN</span>
    </div>

    <div class="card card-small" tabindex="0">
        <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>

      <span>PENAMPANG</span>
    </div>

    <div class="card card-small" tabindex="0">
        <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>

      <span>KEPAYAN</span>
    </div>

    <div class="card card-small" tabindex="0">
      <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>

      <span>KOTA KINABALU</span>
    </div>
  </div>

  <br />
  <!-- Customer review -->
  <h2>Customer Feedback</h2>
  <div class="card-container" style="width: 100%;display: flex;">
    <carousel
      [images]="images">
    </carousel>
  </div>

  <br />
  <!-- Contact Us -->
  <h2 id="contact-us">Hubungi kami / Contact Us</h2>
  <p>Whatsapp</p>
  <p><b>Tekan di bawah - Click Below</b></p>
  <div class="card-container">
    <a class="card" href="https://chat.whatsapp.com/ILg3IJDtTX09733StH9KMf">
      <img
        width="20"
        alt="whatsapp Logo"
        src="assets/images/whatsapp-icon.png"
      />
      &nbsp;
      <span>Whatapps Group (Jualan Laut Segar Putatan)</span>
    </a>

    <a class="card" href="https://chat.whatsapp.com/CMuw1hwK9mK0NhTyu4Ugzm">
      <img
        width="20"
        alt="whatsapp Logo"
        src="assets/images/whatsapp-icon.png"
      />
      &nbsp;
      <span>Whatapps Group (Jualan Laut Segar Putatan 2)</span>
    </a>

    <a class="card" target="_top" href="https://www.facebook.com/hasillautsegarsabah">
      <img
        width="20"
        alt="facebook Logo"
        src="assets/images/facebook-logo.png"
      />
      &nbsp;
      <span>Facebook Page (hasillautsabah.com)</span>
    </a>

    <a class="card" target="_target" href="http://www.wasap.my/60102994665/Order-JLSP">
      <img
        width="20"
        alt="whatapp Logo"
        src="assets/images/whatsapp-icon.png"
      />&nbsp;
      <span>Syahadan (Admin)</span>  
    </a>

    <a class="card" target="_target" href="http://www.wasap.my/601126432314/Order-JLSP">
      <img
        width="20"
        alt="whatsapp Logo"
        src="assets/images/whatsapp-icon.png"
      />
      &nbsp;
      <span>Zandar (Admin)</span>
    </a>
  </div>
  

  <!-- Footer -->
  <footer>
      © 2020 Hasil Laut Sabah.
  </footer>

  <svg id="clouds" alt="Gray Clouds Background" xmlns="http://www.w3.org/2000/svg" width="2611.084" height="485.677" viewBox="0 0 2611.084 485.677">
    <path id="Path_39" data-name="Path 39" d="M2379.709,863.793c10-93-77-171-168-149-52-114-225-105-264,15-75,3-140,59-152,133-30,2.83-66.725,9.829-93.5,26.25-26.771-16.421-63.5-23.42-93.5-26.25-12-74-77-130-152-133-39-120-212-129-264-15-54.084-13.075-106.753,9.173-138.488,48.9-31.734-39.726-84.4-61.974-138.487-48.9-52-114-225-105-264,15a162.027,162.027,0,0,0-103.147,43.044c-30.633-45.365-87.1-72.091-145.206-58.044-52-114-225-105-264,15-75,3-140,59-152,133-53,5-127,23-130,83-2,42,35,72,70,86,49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33,61.112,8.015,113.854-5.72,150.492-29.764a165.62,165.62,0,0,0,110.861-3.236c47,94,178,113,251,33,31.385,4.116,60.563,2.495,86.487-3.311,25.924,5.806,55.1,7.427,86.488,3.311,73,80,204,61,251-33a165.625,165.625,0,0,0,120,0c51,13,108,15,157-5a147.188,147.188,0,0,0,33.5-18.694,147.217,147.217,0,0,0,33.5,18.694c49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33C2446.709,1093.793,2554.709,922.793,2379.709,863.793Z" transform="translate(142.69 -634.312)" fill="#eee"/>
  </svg>

</div>

<!-- Future Added -->
<!-- Home/About/Contact -->
<router-outlet></router-outlet>